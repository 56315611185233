<template>
  <div
    class="blog-divider relative flex flex-col rounded h-full"
  >
     <NuxtLinkLocale :locale="switchBlogLocale(true)" :to="(slug)">
      <NuxtImg
      loading="lazy"
        class="w-full h-auto max-h-[180px] lg:max-h-[149px] object-cover aspect-square rounded"
        :src="blogImage"
        alt="Sunset in the mountains"
        width="300"
        height="152"
      />
    </NuxtLinkLocale>
    <div class="py-2 min-h-[200px] mb-auto">
      <div class="flex text-blog-12-sm">
         <NuxtLinkLocale :locale="switchBlogLocale(true)" class="text-primary font-bold" :to="(categoryUrl)"
          >{{ props.data.categories.name }}
        </NuxtLinkLocale>
        <span class="text-dark-blue-light ml-auto font-semibold">{{
          props.data.post_date
        }}</span>
      </div>
      <div class="flex mt-3 font-bold">
         <NuxtLinkLocale :locale="switchBlogLocale(true)" :to="(slug)">
          <h3 class="text-secondary break-word hover:underline">
            {{ blogTitle }}
          </h3>
        </NuxtLinkLocale>
      </div>
      <!-- <p class="text-base pb-4 break-all" v-html="blogContent"> </p> -->
      <p class="mb-auto pt-1 text-blog-14-sm">{{ blogContent }}</p>
    </div>
    <div
      class="flex pb-2 mt-auto font-semibold text-dark-blue-light text-blog-14"
    >
      <span
        >By
         <NuxtLinkLocale :locale="switchBlogLocale(true)" :to="(authorName)"
          >{{ props.data.author.display_name }}
        </NuxtLinkLocale></span
      >
    </div>
  </div>
</template>

<script setup>
import cardTop from "../../assets/img/card-top.jpg";
const props = defineProps({
  data: {
    type: Object,
  },
});

const { $truncate } = useNuxtApp();
const slug = `/blog/${props.data.slug}`;
const authorName = `/blog/authors/${props.data.author.slug}`;
const categoryUrl = `/blog/categories/${props.data.categories.slug}`;
const blogImage = props.data.image.img_medium
  ? props.data.image.img_medium
  : cardTop;
const blogContent = $truncate(props.data.intro, 150, `...`);
const blogTitle = $truncate(props.data.title, 60, "...");
</script>
